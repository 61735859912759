h1 {
    font-size: 20px;
    padding: 40px 0px;
}

/* .modal {
    position: inherit;
    top: auto;
    left: auto;
    width: auto;
    height: auto;
    background-color: white;
    display: block;
    z-index: 1000;
} */

input {
    width: 100%;
    box-sizing: border-box;
}

.section-action-button {
    display:flex;
    justify-content: center;
    padding-top: 25px;
}

.item-button-container {
    display:flex;
    justify-content: space-around;
    align-items: flex-start;
    margin-top: 20px;
}

.item-button-container button {
    padding: 0;
}

.section .admin_table_row_container {
    text-align: center;
}

.section-event .admin_table_row_container {
    display:grid;
    grid-template-columns: 30% 50% 20%;
}

@media (max-width: 1000px) {
    .admin_table_row_container {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .admin_table_row_container p:nth-child(n+4) {
        display: none;
    }
}
