@font-face {
  font-family: 'Black-Mango';
  src: url('./assets/fonts/BlackMango-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

h1 {
  font-family: "Black-Mango", cursive;
  font-size: 50px;
  margin: 0;
  text-align: center;
}

h2 {
  font-family: "Didact Gothic", sans-serif;
}

button {
  font-family: "Didact Gothic", sans-serif;
  font-size: 20px;
  background: none;
  border: none;
  padding: 3px 20px;
  cursor: pointer;
}

button a svg {
  font-size: 25px;
  margin-bottom: -4px;
  margin-left: 5px;
}

.shopping-cart-btn {
  position: relative;
}

.header-menu-number-icon {
  padding: 0;
  margin: 0;
  background-color: #be7b87;
  color: white;
  border-radius: 50%;
  width: 18px;
  font-weight: bold;
  font-size: 13px;
  position: absolute;
  top: 3px;
  right: 17px;
  z-index: -1;
}

input {
  padding: 5px;
  border-radius: 6px;
  border: 1px solid black;
  font-size: 16px;
}

.important {
  background-color: #d76f7a;
  border: 2px solid #d76f7a;
  border-radius: 8px;
  color: white;
  font-weight: bold;
  transition: 0.3s;
}

.important:hover {
  background-color: #af5a63;
  border: 2px solid #af5a63;
}

.important:disabled {
  background-color: rgb(187, 187, 187);
  border: 2px solid rgb(187, 187, 187);
}

.important a {
  color: white;
  font-weight: bold;
}

a {
  text-decoration: none;
  color: black;
}

.item-container,
.favorite-container {
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
}

.single-item-container,
.favorite-item {
  box-shadow: 0 4px 10px #d3d3d3;
  padding: 15px;
  margin-bottom: 10px;
  position: relative;
  padding-bottom: 100px;
  transition: 0.3s;
}

.single-item-container:hover,
.favorite-item:hover {
  box-shadow: none;
  cursor: pointer;
}

.single-item-bottom-container,
.favorite-bottom-container {
  position: absolute;
  bottom: 3%;
  width: 90%;
}

.single-item-bottom-container button,
.favorite-bottom-container button {
  width: 100%;
}

.singe-item-img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.item-favorite-heart-button {
  width: 35px;
  height: 35px;
  padding: 7px 4px;
  background-color: #ffffff;
  border-radius: 50%;
  position: absolute;
  margin: 5px;
  transition: 0.3s;
}

.item-favorite-heart-button:hover {
  background-color: #f7cdd1;
}

.add-to-cart {
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  cursor: pointer;
  position: relative;
  bottom: 0;
}

.single-item-price,
.favorite-bottom-container-price {
  font-family: "Didact Gothic", sans-serif;
  font-weight: bold;
  font-size: 30px;
  margin: 5px;
}

.content {
  margin-top: 100px;
  margin-bottom: 50px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  background-color: white;
  top: 0;
  width: 100%;
  box-shadow: 0 4px 15px -4px #0000004a;
  z-index: 1;
}

.header div {
  padding: 0px 30px;
}

.header_logo_container {
  display: flex;
  justify-content: center;
}

.header_logo {
  max-height: 100px;
}

.header_buttons_container {
  display: flex;
  justify-content: space-around;
}

.home_banner {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../public/banner.png');
}

.home_banner_text_container {
  text-align: center;
  margin: 80px;
  padding: 20px 40px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 3px 10px #00000045;
}

.section1 {
  display: flex;
  align-items: center;
  background-color: #f7cdd1;
  padding: 5% 10%;
}

.home_section1_text1_container {
  width: 50%;
}

.home_section1_buttons_container button {
  margin: 5px;
}

.home_section1 button {
  margin: 5px;
}

.section2 {
  display: flex;
  justify-content: space-between;
  padding: 5% 10%;
}

.home_section2_text,
.home_section1_text1_container {
  padding-right: 30px;
}

.home_section2_image img {
  max-height: 300px;
}

.footer {
  background-color: black;
  position: fixed;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  padding: 5px;
  transition: height 0.3s ease-in-out;
  height: 50px;
}

.main-menu-btn {
  display: none;
}

.footer.open {
  height: 250px;
  animation: slide-up 0.3s ease-in-out forwards;
  display: block;
}

.footer.open .menu-content {
  opacity: 100;
}

.menu-content {
  align-items: center;
  position: absolute;
  left: 40%;
  height: 180px;
  justify-content: center;
  opacity: 0;
  overflow: hidden;
  transition: opacity .3s ease-in-out;
}

.bottom-menu.open .menu-content {
  opacity: 1;
}

.item-detail-container img {
  max-width: 500px;
  height: auto;
}

/* Slide-up animation */
@keyframes slide-up {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

.menu-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
}

.menu-content li {
  margin: 10px 0;
}

.menu-content a {
  color: white;
  text-decoration: none;
  font-size: 20px;
}

.menu-content a:hover {
  color: #ddd;
}


.footer button {
  color: white;
  font-size: 25px;
  z-index: 3;
}

.footer .left-align button {
  padding: 0px 8px;
  margin-top: 10px;
}

.admin h1 {
  text-align: left;
  margin-bottom: 0;
  padding-bottom: 0;
  font-size: 40px;
}

.admin {
  margin: 0% 10%;
}

.admin_table_heading p {
  font-weight: bold;
}

.admin_table_heading {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.admin_table_row_container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.label-container {
  height: 50px;
  display: flex;
  align-items: center;
}

.label-container a {
  background-color: white;
  border: 1px solid black;
  padding: 2px 10px;
  color: black;
  text-decoration: none;
  border-radius: 10px;
  margin: 3px;
  display: flex;
  align-items: center;
  transition: 0.3s;
  font-family: "Didact Gothic", sans-serif;
}

.label-container a svg {
  margin-right: 5px;
}

.label-container .selected {
  background-color: black;
  color: white;
}

.admin_table_row_container img {
  max-height: 100px;
  max-width: 70px;
}

.section-message .admin_table_row_container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.order_actions {
  display: flex;
  justify-content: space-around;
}

.store .tabs {
  display: flex;
  justify-content: center;
}

.store_content {
  margin: 30px 10%;
}

.store_tabs {
  display: flex;
  margin-bottom: 1rem;
  justify-content: center;
  margin-bottom: 25px;
  padding-top: 30px;
  background-color: #ececec9c;
}

.tab-link {
  padding: 10px 20px;
  margin-right: 5px;
  border: none;
  text-decoration: none;
  cursor: pointer;
  font-family: "Didact Gothic", sans-serif;
  transition: 0.3s;
  text-align: center;
}

.tab-link:hover,
.tab-link.active {
  background-color: white;
}

.store_tab_content,
.favorite-items {
  margin: 0px 10%;
}

.login_form_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login_text {
  text-align: center;
}

.login-form {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid lightgray;
  padding: 30px;
  border-radius: 10px;
}

.login_btn_submit {
  width: 100%;
  margin-top: 18px;
  font-size: 15px;
  font-weight: bold;
  color: white;
}

.login_buttons_container {
  display: flex;
  justify-content: center;
}

.login_buttons_container button {
  margin: 5px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-width: 100%;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

th {
  text-align: left;
}

thead {
  font-weight: bold;
}

.header_nav_menu_container button {
  transition: 0.5s;
  border-bottom: 2px solid white;
}

.header_nav_menu_container button:hover {
  border-bottom: 2px solid pink;
}

.single-event-container {
  list-style: none;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 10px #d3d3d3;
}

.all-events-container {
  padding: 0% 10%;
}

.single-event-container p {
  display: flex;
  align-items: center;
}

.custom-order-formblock-collection {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
}

.custom-order-formblock input,
textarea {
  width: 100%;
  box-sizing: border-box;
  font-size: 18px;
}

.custom-order-form {
  margin: 0 20%;
  box-shadow: 0 4px 10px #d3d3d3;
  border-radius: 10px;
  padding: 20px 10%;
}

.custom-orders-submit-btn {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.custom-order-formblock {
  padding: 5px;
}

textarea {
  width: 100%;
  border-radius: 8px;
  resize: none;
  padding: 5px;
  box-sizing: border-box;
}

.custom-order-submitted {
  color: white;
  background-color: rgb(215, 111, 122);
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 14px;
}

.header_contact_menu_container button {
  transition: 0.3s;
  margin: 10px;
  border-radius: 50%;
  padding: 8px;
}

.header_contact_menu_container button:hover {
  background-color: #d76f7a;
}

.header_contact_menu_container svg {
  font-size: 25px;
  margin-bottom: -8px;
  margin-left: 0px;
  padding: 6px;
  transition: 0.3s;
}

.cart-item {
  display: grid;
  grid-template-columns: 10% 10% 10% 50% 10% 10%;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 10px #d3d3d3;
  margin: 10px 0;
}

.cart-item img {
  max-height: 60px;
}

.cart-item-buttons {
  display: flex;
}

.cart-item-buttons button {
  margin: 0;
  padding: 10px;
}

.checkout-btn {
  float: right;
  display: flex;
  align-items: center;
}

.checkout-btn svg {
  font-size: 23px;
}

.cart {
  margin: 0 10%;
}

.cart-item h3,
.cart-item p {
  padding: 10px;
  margin: 0;
}

.cart-total-container h3,
.cart-total-container p {
  text-align: right;
  font-size: 20px;
}

.cart-item-buttons button svg {
  font-size: 25px;
  margin-bottom: -8px;
  margin-left: 0px;
  padding: 6px;
  transition: 0.3s;
}

.cart-item-buttons button {
  border-radius: 50%;
  transition: 0.3s;
  height: 35px;
  padding: 0px;
}

.cart-item-buttons button:hover {
  background-color: #d76f7a;
}

.item-detail-warning {
  font-weight: bold;
  color: white;
  background-color: #9f9f9f;
  padding: 10px 20px;
  text-align: center;
  border-radius: 5px;
}

.custom-order-button-container {
  display: flex;
  justify-content: center;
}

.custom-order-button-container button {
  transition: 0.3s;
  margin: 10px;
  border-radius: 50%;
  padding: 10px 10px;
  display: flex;
  justify-content: center;
}

.custom-order-button-container button:hover {
  background-color: #d76f7a;
}

.custom-message-form-title {
  margin: 0;
  text-align: center;
}

.cart-options-container {
  display: flex;
  justify-content: right;
}

.cart-options-container button {
  background: none;
  color: black;
  border: none;
  text-decoration: underline;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 500px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.close-btn {
  cursor: pointer;
  top: 0;
  right: 0;
  color: black;
  position: relative;
  float: right;
  background-color: #d3d3d3;
  border-radius: 50%;
  padding: 5px;
  font-size: 30px;
  display: flex;
  border: 2px solid #d3d3d3;
}

.modal-cart-items {
  margin-top: 20px;
  background-color: #e4e4e4;
  padding: 10px;
  border-radius: 5px;
}

.modal-cart-item {
  display: grid;
  grid-template-columns: 35% 50% 15%;
  margin-bottom: 10px;
}

.modal-cart-item p,
.modal-cart-item h3 {
  margin: 0;
}

.modal-cart-total {
  margin-top: 20px;
  text-align: right;
}

.modal-cart-form {
  display: flex;
  justify-content: center;
}

.modal-cart-form form {
  width: 100%;
}

.form-group {
  margin: 10px 0;
}

.form-group input {
  width: 100%;
  box-sizing: border-box;
}

.cart-submit {
  float: right;
}

.modal-cart-items-price {
  text-align: right;
}

.order-submitted-warning {
  background-color: #9f9f9f;
  text-align: center;
  border-radius: 5px;
  padding: 10px;
  color: white;
}

.store-main-menu-btn {
  display: none;
}

.custom-message-form {
  padding: 30px 25%;
}

@media (max-width: 1000px) {

  .header {
    display: flex;
    justify-content: center;
  }

  .header_contact_menu_container {
    display: none;
  }

  .header_nav_menu_container {
    display: none;
  }

  .header_logo {
    max-height: 100px;
  }

  .content {
    margin-top: 100px;
  }

  .section1,
  .section2 {
    display: block;
  }

  .home_section1_text1_container {
    width: 100%;
  }

  .home_section2_text {
    padding-bottom: 20px;
  }

  .main-menu-btn {
    font-size: 30px !important;
    margin-top: 5px;
    background-color: black;
    border-radius: 50%;
    padding: 10px;
    position: absolute;
    bottom: 20px;
    right: 30px;
    height: 55px;
    width: 55px;
  }

  .main-menu-btn {
    display: block;
  }

  .item-container,
  .favorite-container {
    display: block;
  }

  .custom-order-form {
    margin: 0;
  }

  .cart-item {
    display: grid;
    justify-content: space-between;
    grid-template-columns: 25% 40% 25% 10%;
  }

  .cart-item-description,
  .cart-item-collection {
    display: none;
  }

  .item-detail-container {
    display: block !important;
  }

  .item-detail-container img {
    max-width: 300px;
  }

  .cart {
    padding-bottom: 80px;
  }

  .modal {
    padding: 0px;
  }

  .store-main-menu-btn {
    display: block;
    position: absolute;
    top: 15px;
    right: 15px;
    margin: 0;
    background-color: #ed969e;
    border-radius: 50%;
    color: white;
    padding: 10px 10px 0px 10px;
  }

  .store-main-menu-btn a svg {
    margin: 0;
  }

  .custom-message-form {
    padding: 10%;
  }

  .label-container {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 33% 33% 33%;
    height: auto;
    font-size: 12px;
  }

  .label-container a,
  .label-container a:focus {
    padding: 2px 5px;
  }
}