@font-face {
    font-family: 'Black-Mango'; 
    src: url('../assets/fonts/BlackMango-Bold.ttf') format('truetype'); 
    font-weight: normal;
    font-style: normal;
}

.item-detail-container {
    display:flex;
}

.item-detail-container-details {
    padding: 20px;
}

.item-detail-container-details h3 {
    font-family: "Didact Gothic", sans-serif;
    font-size:50px;
    margin: 0;
}

.item-detail-price-banner {
    width:100%;
    padding: 10px;
    background-color: #e3979f;
    color:white;
    font-weight:bold;
    border-radius:5px;
    box-sizing: border-box;
}

.item-detail-price-banner p {
    margin:0;
    padding:0;
    text-align: center;
}

.add-to-cart {
    width: 100%;
    padding: 10px;
    border-radius: 20px;
}